import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useRef, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import { apiGetNotifications, apiUpdateNotification } from '../../_apis_/notifications'
// material
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@material-ui/core';
import { fToNow } from '../../utils/formatTime';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification?.message)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'NEW PAYMENT') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_package.svg" />,
      title
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_shipping.svg" />,
      title
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_mail.svg" />,
      title
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/static/icons/ic_notification_chat.svg" />,
      title
    };
  }
  return {
    avatar: <img alt={notification.title} src={notification.avatar} />,
    title
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

function NotificationItem({ notification, setOpen }) {
  const { avatar, title } = renderContent(notification);

  const handleNotificationClick = async (notification) => {
    const result = await apiUpdateNotification(notification?.id);
  }
  return (
    <ListItemButton
      to={notification?.link ?? '#'}
      onClick={
        () => {
          handleNotificationClick(notification)
          setOpen(false)
        }}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created_at)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [totalUnRead, setTotalUnread] = useState(0);

  const fetchNotifications = async () => {
    setLoading(true);
    const result = await apiGetNotifications('ADMIN');
    if (result.status === 'success') {
      setTotalUnread(result?.totalDocuments);

      const temp = result.data?.slice(0, 6)?.map((note) => {
        let link;
        if (note?.type == 'NEW PAYMENT') {
          link = `/dashboard/payments`;
        } else if (note?.type == 'NEW TEAM SOURCE') {
          link = `/dashboard/team/`;
        } else {
          link = `/dashboard/single_source/`;
        }
        return {
          id: note?._id,
          title: note?.title,
          message: note?.message,
          avatar: null,
          type: note?.type,
          created_at: note?.created_at,
          link: link,
          isUnRead: note?.status == 'unread' ? false : true
        }
      })
      setNotifications(temp);
    } else if (result.status === 'error') { }
    setLoading(false);
  };

  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        status: 'read'
      }))
    );
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>
        <Divider />

        <Scrollbar sx={{
          height: {
            xs: 340, sm: 'auto',
          },
          overflow: 'scroll',
        }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{
                py: 1, px: 2.5,
                typography: 'overline',
                overflow: 'scroll',
              }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 10).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} onClick={() => handleMarkAsRead(notification?.id)} setOpen={setOpen} />
            ))}
          </List>
        </Scrollbar>
        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            Refresh
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
