import request from '../assets/utils/http-request';
import { getRequestConfiguration } from '../assets/utils/helper';

export const apiAdminLogin = async (values) => {
    let uri = '';
    try {
        const uri = `/admin/auth/login`;
        return (await request.post(uri, values, {}))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


export const apiGetAdminDetails = async (token) => {
    let uri = '';
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    try {
        console.log('values');
        const uri = `/admin/admin/get-admin`;
        return (await request.get(uri, header))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}



export const apiUpdateAdminDetails = async (values, id) => {
    const cfg = await getRequestConfiguration();
    let uri = '';
    try {
        const uri = `/admin/admin/update-admin/${id}`;
        return (await request.put(uri, values, cfg)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

export const apiChangePassword = async (values, id) => {
    const cfg = await getRequestConfiguration();
    let uri = '';
    try {
        const uri = `/admin/admin/update-password/${id}`;
        return (await request.put(uri, values, cfg)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}