import { SpinnerCircular, SpinnerDotted } from 'spinners-react';
import { styled } from '@material-ui/core/styles';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background?.default
}));

export default function LoadingScreen({ ...other }) {
  return (
    <RootStyle {...other}>
      <SpinnerDotted
        size={60}
        color='orange'
        speed={200} />
    </RootStyle>
  );
}
