import { useState, createContext } from "react";

export const DataContext = createContext()

export default function DataContextProvider(props) {
    const [task, setTask] = useState()
    const [consultants, setConsultants] = useState()

    return (
        <DataContext.Provider value={{ task, setTask, consultants, setConsultants }}>
            {props.children}
        </DataContext.Provider>
    );
}