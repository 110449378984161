import { useState, createContext } from "react";

export const ClientContext = createContext()

export default function ClientContextProvider(props) {
    const [data, setData] = useState([]);
    const [number, setNumber] = useState(1);
    const [page, setPage] = useState({ title: '', description: '', showButton: true, buttonComponent: <></> });

    return (
        <ClientContext.Provider value={{ data, setData, number, setNumber, page, setPage }}>
            {props.children}
        </ClientContext.Provider>
    );
}

