import axios from "axios";

let uri;

if (process.env.REACT_APP_ENVIRONMENT == 'development') {
    uri = 'http://localhost:5004/v1' // local
} else if (process.env.REACT_APP_ENVIRONMENT == 'staging') {
    uri = 'https://dpd-staging-ad4164fec942.herokuapp.com/v1' // staging
} else if (process.env.REACT_APP_ENVIRONMENT == 'production') {
    uri = 'https://dpd-online-server.herokuapp.com/v1' // production
}

const Axios = axios.create({ baseURL: uri });
export default Axios;

