

export const getRequestConfiguration = () => {
    const token = window.localStorage.getItem('token');
    if (!token) return window.location.replace('/auth/login')
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    return header
}

export function formatNumberWithCommas(number) {
    if (typeof number !== 'number') {
        throw new Error('Input must be a number');
    }

    return number.toLocaleString();
}
