import request from '../assets/utils/http-request';
import { getRequestConfiguration } from '../assets/utils/helper';


export const apiGetNotifications = async (user) => {
    const cfg = await getRequestConfiguration();
    let uri = '';
    try {
        const uri = `/admin/notifications/get-notifications?q=${user}`;
        let result = (await request.get(uri, cfg)).data
        return result
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

export const apiUpdateNotification = async (id) => {
    const cfg = await getRequestConfiguration();
    let uri = '';
    try {
        const uri = `/admin/notifications/update-notification/${id}`;
        let result = (await request.put(uri, {}, cfg)).data
        return result
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

