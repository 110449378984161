// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Container, Typography, Button } from '@material-ui/core';
// routes
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | 1capacity">
      <MHidden width="mdDown" >
        <SectionStyle sx={{ background: "#FAECE7", margin: "0px" }} >
          <img src="/static/illustrations/logo3.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container sx={{
        backgroundImage: "url(" + "/static/illustrations/dashboard.png" + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: "100vh",
        display: "flex",
        alignItems: "center",
        maxWidth: "100%!important"
      }}>
        <ContentStyle sx={{
          backdropFilter: "blur(5px)", background: "white", padding: "60px", minHeight: "400px", minHeight: "400px", borderRadius: "20px", width: "500px"
        }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
                Sign in as admin
              </Typography>
              <Typography sx={{ color: 'text.secondary', textAlign: "center" }}>Enter your details below.</Typography>
            </Box>
          </Stack>

          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              Login
            </Button>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
