import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import useAuth from 'src/hooks/useAuth';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { ClientContext } from 'src/contexts/ClientContext';

const DRAWER_WIDTH = 200;
const COLLAPSE_WIDTH = 52;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 22;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(1, 2)
  }
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const { page, setPage } = useContext(ClientContext);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setPage({ ...page, title: `Hello ${user?.first_name}`, description: 'Interact/Organise users interactions here' })
  }, [])

  return (
    <RootStyle
      sx={{
        background: '#FFFAF3',
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Box>
          <Typography variant="body2" sx={{ color: 'text.primary', fontSize: '18px', fontWeight: '700' }}>
            {page?.title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '12px', fontWeight: '300' }}>
            {page?.description}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {page?.showButton && page?.buttonComponent}
          <NotificationsPopover />
          <Box sx={{ display: 'flex' }}>
            <AccountPopover />
          </Box>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
