export const ADMIN = 'ADMIN'
export const PAYMENTTYPES = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
    FEE: 'FEE'
};

export const PAYMENTMODES = {
    ADMIN_RESOLVE_PAYMENT: 'ADMIN RESOLVE PAYMENT',
};
