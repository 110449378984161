import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'change-password', element: <ChangePassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'single_source', element: <GeneralOutsourcing /> },
        { path: 'hire-consultant', element: <GeneralConsultant /> },
        { path: 'team', element: <GeneralTeam /> },
        {
          path: 'hire-consultant', children: [
            { path: 'details', element: <HireConsultantDetails /> }
          ]
        },
        {
          path: 'team', children: [
            { path: 'new', element: <TeamCreate /> },
            { path: 'details/:id', element: <HireTeamDetails /> },

          ]
        },
        { path: 'consultants', element: <Consultants /> },
        { path: 'payments', element: <GeneralPayments /> },
        {
          path: 'payments',
          children: [
            { path: '', element: <GeneralPayments /> },
            { path: 'payments/:id', element: <PaymentDetails /> },
          ]
        },
        {
          path: 'single_source',
          children: [
            { path: 'details/:id', element: <OutsourceDetails /> }
          ]
        },
        // {
        //   path: 'team',
        //   children: [
        //   ]
        // },
        {
          path: 'user',
          children: [
            // { path: '/', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'escrow',
          children: [
            { path: '', element: <Escrow /> }
          ]
        },
        {
          path: 'support',
          children: [
            { path: '', element: <Support /> },
            { path: ':id', element: <SupportInfo /> },
          ]
        },
        {
          path: 'requests',
          children: [
            { path: '', element: <Requests /> },
            { path: ':id', element: <RequestsInfo /> },
          ]
        },
        {
          path: 'mail',
          children: [
            // { path: '/', element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        { path: 'calendar', element: <Calendar /> },
        {
          path: 'consultants',
          children: [
            { path: 'onboarding', element: <ConsultantsOnboarding /> },
            { path: 'onboarding/:id', element: <ConsultantsOnboardingDetails /> },
            { path: 'consultant_lists', element: <Consultants /> },
            { path: 'consultant_info/:id', element: <Consultant /> },
          ]
        },
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'processing-account', element: <ComingSoon /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" replace /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralOutsourcing = Loadable(lazy(() => import('../pages/dashboard/GeneralOutsourcing')));
const GeneralConsultant = Loadable(lazy(() => import('../pages/dashboard/GeneralConsultant')));
const GeneralTeam = Loadable(lazy(() => import('../pages/dashboard/GeneralTeam')));

const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

const Consultants = Loadable(lazy(() => import('../pages/dashboard/Consultants')));
const ConsultantsOnboarding = Loadable(lazy(() => import('../pages/dashboard/ConsultantsOnboarding')));
const ConsultantsOnboardingDetails = Loadable(lazy(() => import('../pages/dashboard/ConsultantsOnboardingDetails')));
const Consultant = Loadable(lazy(() => import('../pages/dashboard/Consultant')));
const GeneralPayments = Loadable(lazy(() => import('../pages/dashboard/GeneralPayments')));
const PaymentDetails = Loadable(lazy(() => import('../pages/dashboard/PaymentDetails')));

const Support = Loadable(lazy(() => import('../pages/dashboard/Support')));
const SupportInfo = Loadable(lazy(() => import('../pages/dashboard/SupportInfo')));

const Escrow = Loadable(lazy(() => import('../pages/dashboard/Escrow')));

const Requests = Loadable(lazy(() => import('../pages/dashboard/Requests')));
const RequestsInfo = Loadable(lazy(() => import('../pages/dashboard/RequestsInfo')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const OutsourceDetails = Loadable(lazy(() => import('../pages/dashboard/OutsourceDetails')));
const HireConsultantDetails = Loadable(lazy(() => import('../pages/dashboard/HireConsultantDetails')));
const TeamCreate = Loadable(lazy(() => import('../pages/dashboard/TeamCreate')));
const HireTeamDetails = Loadable(lazy(() => import('../pages/dashboard/HireTeamDetails')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const NotFound = Loadable(lazy(() => import('../pages/Page404'))); 